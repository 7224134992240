import React, { createContext, useState, useEffect } from 'react';
const CartContext = createContext();

export const CartProvider = ({children}) => {
    const [experiences, setExperiences] = useState([])
    const [cartItem, setCartItem] = useState() 
    const [show, setShow] = useState("")
    const [visible, setVisible] = useState("")
    const [isFocus, setIsFocus] = useState(false)

    const [startDate, setStartDate] = useState(new Date());
    const [numChild, setNumChild] = useState("");
    const [numAdults, setNumAdults] = useState("");
    const [childPrice, setChildPrice] = useState(new Date());
    const [adultPrice, setAdultPrice] = useState("");
    const [totalPrice, setTotalPrice] = useState("");


    useEffect(() => {
      const savedProducts = JSON.parse(localStorage.getItem('experience'));
      if (savedProducts) {
        setExperiences(savedProducts);
      }
    }, []);

    const addToCart = (experience, experienceExist) => {
        if(!experienceExist){
          setExperiences([...experiences, experience])
          setShow("show")
          setVisible("show")
          localStorage.setItem('experience', JSON.stringify([...experiences, experience]));
        }
    }
    const removeFromCart = (experience) => {
        setExperiences(experiences.filter(p => p.id !== experience.id));
        localStorage.setItem('experience', JSON.stringify(experiences.filter(p => p.id !== experience.id)));
    }

    const deleteAll = (experiences) => {
      setExperiences(experiences.splice(0, experiences.length));
      localStorage.setItem('experience', JSON.stringify(experiences.splice(0, experiences.length)));
    }

    const values = {
      isFocus, 
      setIsFocus,
       visible,
       setVisible,
       show,
       setShow,
       cartItem,
       setCartItem,
       experiences,
       addToCart,
       deleteAll,
       removeFromCart,
        startDate,
        setStartDate,
        numAdults,
        setNumAdults,
        numChild,
        setNumChild,
        childPrice,
        setChildPrice,
        adultPrice,
        setAdultPrice,
        totalPrice,
        setTotalPrice
      }
    return (
        <CartContext.Provider value={values}>
          {children}
        </CartContext.Provider>
      )
}

export default CartContext