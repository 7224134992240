/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/assets/scss/styles.scss"
import React from "react"
import { CartProvider } from "./src/context/CartContext"
export const wrapRootElement = ({element }) =>{
    return <CartProvider>{element}</CartProvider>
}

// const addScript = url => {
//     const script = document.createElement("script")
//     script.src = url
//     script.async = true
//     document.body.appendChild(script)
// }
//
// export const onClientEntry = () => {
//     window.onload = () => {
//         addScript("https://www.tripadvisor.co.uk/WidgetEmbed-selfserveprop?border=true&popIdx=true&iswide=false&locationId=550442&display_version=2&uniq=361&rating=false&lang=en_UK&nreviews=0&writereviewlink=false")
//     }
// }
